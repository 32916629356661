import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageContextType } from 'types';
import { IndustriesType, JobOffersType, OurServicesType } from 'types/home-page.d';
import { AllJobOfferType } from 'types/job-offers';

import Layout from 'components/templates/layout';
import BaseCardListing from 'components/organisms/base-card-listing';
import HrkNumbers, { HrkInNumbersType } from 'components/organisms/hrk-numbers';
import IndustriesSlider from 'components/organisms/industries-slider';
import JobOffersBanner from 'components/organisms/job-offers-banner';
import JobOffersSlider from 'components/organisms/job-offers-slider';
import KnowHowSlider, { OtherPostsType } from 'components/organisms/know-how-slider';
import OurDepartments, { OurDepartmentsType } from 'components/organisms/our-departments';
import AboutBanner, { AboutBannerType } from 'components/molecules/about-banner';
import HeroBanner, { HeroBannerType } from 'components/molecules/hero-banner';
import LetsTalkBanner, { LetsTalkBannerType } from 'components/molecules/lets-talk-banner';

type DataProps = {
  homePage: {
    homeData: {
      hero: HeroBannerType;
      hrkInNumbers: HrkInNumbersType;
      ourServices: OurServicesType;
      industries: IndustriesType;
      aboutUs: AboutBannerType;
      ourDepartments: OurDepartmentsType;
      jobOffers: JobOffersType;
      otherPosts?: OtherPostsType;
    };
  };
  allJobOfferType: AllJobOfferType;

  commonComponents: {
    commonComponentsData: {
      letsTalkBanner: LetsTalkBannerType;
    };
  };
};

const HomePage = ({
  pageContext,
  data: {
    homePage: {
      homeData: {
        ourServices,
        industries,
        hero,
        aboutUs,
        hrkInNumbers,
        jobOffers,
        otherPosts,
        ourDepartments,
      },
    },
    allJobOfferType,
    commonComponents: {
      commonComponentsData: { letsTalkBanner },
    },
  },
}: PageProps<DataProps, PageContextType>) => (
  <Layout {...{ pageContext }}>
    <HeroBanner {...hero} />
    <JobOffersBanner
      image={jobOffers.banner}
      searchBar={{
        positionPlaceholder: jobOffers.search.positionPlaceholder,
        industriesPlaceholder: jobOffers.search.industriesPlaceholder,
        button: jobOffers.search?.button,
        allJobOfferType,
      }}
    />

    <JobOffersSlider
      heading={jobOffers.heading}
      subHeading={jobOffers.subHeading}
      linkButton={jobOffers.linkButton}
      cards={allJobOfferType.edges}
    />
    <BaseCardListing {...ourServices} showDescription="desktop" cards={ourServices.servicesList} />
    <IndustriesSlider {...industries} cards={industries.industriesList} />
    <AboutBanner {...aboutUs} />
    <HrkNumbers {...hrkInNumbers} />
    <OurDepartments {...ourDepartments} />
    <LetsTalkBanner {...letsTalkBanner} />
    {otherPosts?.postsList ? <KnowHowSlider {...otherPosts} /> : null}
  </Layout>
);

export default HomePage;

// cG9zdDoyNA==

export const query = graphql`
  query HomePage($id: String, $commonComponentsSlug: String!) {
    homePage: wpPage(id: { eq: $id }) {
      homeData {
        otherPosts {
          heading
          subHeading
          linkButton {
            icon {
              name
            }
            label
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              external {
                target
                url
              }
            }
          }
          postButton {
            label
            ariaLabel
          }
          postsList {
            ... on WpKnow_how {
              id
              featuredImage {
                node {
                  ...WpImageFragment
                }
              }
              date(formatString: "DD.MM.YYYY")
              tags {
                nodes {
                  name
                  uri
                }
              }
              categories {
                nodes {
                  name
                  slug
                }
              }
              title
              uri
            }
            ... on WpPress {
              id
              featuredImage {
                node {
                  ...WpImageFragment
                }
              }
              date(formatString: "DD.MM.YYYY")
              tags {
                nodes {
                  name
                  uri
                }
              }
              title
              uri
            }
          }
        }
        hero {
          heading
          features {
            text
            icon {
              name
            }
          }
        }
        jobOffers {
          subHeading
          heading
          banner {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              url
            }
          }
          search {
            positionPlaceholder
            industriesPlaceholder
            button {
              icon {
                name
              }
              label
              ariaLabel
            }
          }
          linkButton {
            icon {
              name
            }
            label
            link {
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
            }
          }
        }
        hrkInNumbers {
          heading
          backgroundImg {
            altText
            localFile {
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          sectionInNumbers {
            numberText
            descriptionNumber
          }
        }
        ourServices {
          heading
          subHeading
          servicesList {
            description
            heading
            icon {
              name
            }
            linkButton {
              label
              icon {
                name
              }
              link {
                ariaLabel
                internal: wpInternal {
                  ... on WpPage {
                    uri
                  }
                }
                external {
                  target
                  url
                }
              }
            }
          }
        }
        ourDepartments {
          description
          heading
          departmentsList {
            name
            positionx
            positiony
            link {
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
            }
          }
        }
        industries {
          heading
          subHeading
          linkButton {
            icon {
              name
            }
            label
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              external {
                target
                url
              }
            }
          }
          industriesList {
            heading
            icon {
              name
            }
            linkButton {
              label
              icon {
                name
              }
              link {
                ariaLabel
                internal: wpInternal {
                  ... on WpPage {
                    uri
                  }
                }
                external {
                  target
                  url
                }
              }
            }
          }
        }
        aboutUs {
          description
          heading
          subHeading
          linkButton {
            label
            icon {
              name
            }
            link {
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              external {
                target
                url
              }
            }
          }
        }
        industries {
          heading
          subHeading
          linkButton {
            icon {
              name
            }
            label
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              external {
                target
                url
              }
            }
          }
          industriesList {
            heading
            icon {
              name
            }
            linkButton {
              label
              icon {
                name
              }
              link {
                ariaLabel
                internal: wpInternal {
                  ... on WpPage {
                    uri
                  }
                }
                external {
                  target
                  url
                }
              }
            }
          }
          linkButton {
            label
            icon {
              name
            }
            link {
              ariaLabel
              external {
                target
                url
              }
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
            }
          }
        }
      }
    }
    allJobOfferType: allWpJobOffer(
      sort: { fields: [date], order: DESC }
      filter: { offerTypes: { nodes: { elemMatch: { name: { eq: "OfertaKlienta" } } } } }
    ) {
      edges {
        node {
          uri
          title
          date
          offersData {
            consultant
            category
            voivodship
            city
            contracttypes
            salaryfrom
            salaryto
          }
        }
      }
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        letsTalkBanner {
          heading
          description
          linkButton {
            label
            icon {
              name
            }
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              external {
                target
                url
              }
            }
          }
        }
      }
    }
  }
`;
