import React from 'react';

import Typography from 'components/atoms/typography';
import WpImage, { WpImageType } from 'components/atoms/wp-image';

import {
  BoxNumbers,
  StyledContainer,
  StyleDescriptionNumber,
  StyledWrapper,
  StyleItemNumber,
  StyleTitle,
} from './hrk-numbers.styles';

export type HrkInNumbersType = {
  heading: string;
  backgroundImg: WpImageType;
  sectionInNumbers: {
    numberText: string;
    descriptionNumber: string;
  }[];
};

const HrkNumbers = ({ sectionInNumbers, backgroundImg, heading }: HrkInNumbersType) => (
  <StyledWrapper>
    <StyledContainer>
      <WpImage {...backgroundImg} />
      <BoxNumbers>
        <StyleTitle variant="title6" color="whiteAlpha72">
          {heading}
        </StyleTitle>
        <div>
          {sectionInNumbers.map((item) => (
            <StyleItemNumber>
              <Typography variant="title1" component="h3" color="white">
                {item.numberText}
              </Typography>
              <StyleDescriptionNumber variant="title6" color="whiteAlpha72">
                {item.descriptionNumber}
              </StyleDescriptionNumber>
            </StyleItemNumber>
          ))}
        </div>
      </BoxNumbers>
    </StyledContainer>
  </StyledWrapper>
);

export default HrkNumbers;
