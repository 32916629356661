import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { AllJobOfferType } from 'types/job-offers';

import {
  categoryTranslations,
  staticCategoty,
} from 'components/organisms/search-with-filter/staticData';
import { ButtonType } from 'components/atoms/button';
import Input from 'components/atoms/input';

import useCurrentLanguage from '../../../hooks/use-current-language';

import { StyledButton, StyledForm, StyledWrapper } from './job-offers-search.styles';

export type JobOffersSearchType = {
  positionPlaceholder: string;
  industriesPlaceholder: string;
  button: ButtonType;
  allJobOfferType: AllJobOfferType;
};

const JobOffersSearch = ({
  positionPlaceholder,
  industriesPlaceholder,
  button,
  allJobOfferType,
}: JobOffersSearchType) => {
  const languageCode = useCurrentLanguage();
  const [selectValue, setSelectValue] = useState<string | null>('');
  const [searchText, setSearchText] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categories, setCategories] = useState<{ value: string; label: string }[]>([]);

  const handleSelectChange = (option) => {
    setSelectedCategory(option.value);
    setSelectValue(option);
  };
  const getCategoryLabel = (category) => categoryTranslations[languageCode][category] || category;

  useEffect(() => {
    const allCategoriesFromData = allJobOfferType.edges
      .map((offer) => offer.node.offersData.category)
      .filter((v, i, a) => v && a.indexOf(v) === i);

    const mergedCategories = Array.from(new Set([...staticCategoty, ...allCategoriesFromData]));

    const categoryObjects = mergedCategories.map((name) => ({
      value: name,
      label: getCategoryLabel(name),
    }));

    setCategories(categoryObjects);
  }, [allJobOfferType, languageCode]);

  const handleSearch = () => {
    const navigateUrlPL = searchText ? `/job-offers/?search=${searchText}` : '/job-offers/?';
    const navigateUrlEN = searchText ? `/en/job-offers/?search=${searchText}` : '/en/job-offers/?';
    let navigateUrl = languageCode === 'PL' ? navigateUrlPL : navigateUrlEN;
    if (selectedCategory) {
      const selectedCategoryIndex = categories.findIndex(
        (category) => category.value === selectedCategory
      );
      navigateUrl += searchText
        ? `&category=${selectedCategoryIndex}`
        : `category=${selectedCategoryIndex}`;
    }
    navigate(navigateUrl);
  };

  return (
    <StyledWrapper>
      <StyledForm
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Input
          name="position"
          placeholder={positionPlaceholder}
          value={searchText}
          handleChange={(e) => setSearchText(e.target.value)}
          type="text"
        />
        <Input
          name="industries"
          options={categories}
          value={selectValue}
          handleChange={(value) => handleSelectChange(value)}
          placeholder={industriesPlaceholder}
          type="select"
        />
        <StyledButton {...button} size="lg" onClick={handleSearch} />
      </StyledForm>
    </StyledWrapper>
  );
};

export default JobOffersSearch;
