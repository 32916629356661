import styled from 'styled-components';
import { breakpoint } from 'theme';

import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div`
  padding-inline: ${({ theme }) => theme.gap.sm};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 36px;

  ${breakpoint.md} {
    padding-block: ${({ theme }) => theme.gap.xl};
  }
`;

export const StyledContent = styled.div`
  max-width: ${({ theme }) => theme.wrapper};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.gap.xs};

  ${breakpoint.md} {
    gap: ${({ theme }) => theme.gap.sm};
  }
`;

export const StyledSection = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint.md} {
    flex-direction: row;
    gap: 32px;
  }
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint.md} {
    width: 50%;
  }
`;

export const StyledSubHeading = styled(Typography)`
  width: 100%;
`;

export const StyledTitle = styled(Typography)`
  word-break: keep-all;

  ${breakpoint.md} {
    width: 50%;
  }
`;

export const StyledDescription = styled(Typography)`
  padding-top: 16px;
  margin-bottom: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    margin-bottom: 36px;
    padding-top: 10px;
  }
`;
