import styled from 'styled-components';
import { breakpoint } from 'theme';

import DotsGrid from 'components/molecules/dots-grid';
import WpImage from 'components/atoms/wp-image';

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 165px;
  position: relative;

  ${breakpoint.md} {
    margin-top: 120px;

    &::after {
      content: '';
      background: ${({ theme }) => theme.colors.grayscaleGray0};
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 50%;
      z-index: -1;
    }
  }
`;

export const StyledImageWrapper = styled.div`
  position: relative;
  width: auto;
`;

export const StyledWpImage = styled(WpImage)`
  max-width: 1440px;
  min-height: 256px;
`;

export const StyledDotsGridOne = styled(DotsGrid)`
  top: -56px;
  left: 8px;
  z-index: 1;

  ${breakpoint.md} {
    top: -235px;
    left: 16px;
  }

  ${breakpoint.xxl} {
    left: -190px;
    z-index: -1;
  }
`;

export const StyledDotsGridTwo = styled(DotsGrid)`
  bottom: -12px;
  right: 8px;
  z-index: 1;

  ${breakpoint.md} {
    bottom: -33px;
    right: 6px;
  }

  ${breakpoint.xxl} {
    right: -42px;
  }
`;
