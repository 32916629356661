import styled from 'styled-components';
import { breakpoint } from 'theme';

import Button from 'components/atoms/button';

export const StyledWrapper = styled.div`
  max-width: ${({ theme }) => theme.wrapper};
  width: calc(100% - ${({ theme }) => theme.gap.md});
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 12px rgba(26, 26, 26, 0.1), 0px 16px 24px -2px rgba(26, 26, 26, 0.12);
  padding: 24px;
  margin: 0 auto;
  position: absolute;
  top: -130px;
  left: 0;
  right: 0;
  z-index: 2;

  ${breakpoint.md} {
    width: 100%;
    top: -49px;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  gap: 16px;
  align-items: center;
  flex-direction: column;

  ${breakpoint.md} {
    flex-direction: row;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  justify-content: center;

  ${breakpoint.md} {
    max-width: 192px;
  }
`;
