import styled from 'styled-components';
import { breakpoint } from 'theme';

import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-inline: 24px;
  padding-top: 36px;

  ${breakpoint.md} {
    padding-top: ${({ theme }) => theme.gap.xl};
  }
`;

export const StyledContent = styled.div`
  max-width: 966px;
`;

export const StyledTitle = styled(Typography)`
  max-width: 918px;
`;

export const StyledFeatures = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;

  flex-direction: column;

  ${breakpoint.md} {
    flex-direction: row;
    margin-top: 32px;
    gap: 24px;
  }
`;

export const StyledFeature = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.colors.complementary2};
  }
`;
