import React from 'react';

import Button, { ButtonType } from 'components/atoms/button';

import {
  StyledColumn,
  StyledContent,
  StyledDescription,
  StyledSection,
  StyledSubHeading,
  StyledTitle,
  StyledWrapper,
} from './about-banner.styles';

export type AboutBannerType = {
  subHeading: string;
  heading: string;
  description: string;
  linkButton: ButtonType;
};

const AboutBanner = ({ subHeading, heading, description, linkButton }: AboutBannerType) => (
  <StyledWrapper>
    <StyledContent>
      <StyledSubHeading variant="title6" color="gray40" component="h3">
        {subHeading}
      </StyledSubHeading>
      <StyledSection>
        <StyledTitle variant="title1" html={heading} />
        <StyledColumn>
          <StyledDescription variant="body1" html={description} />
          <Button {...linkButton} variant="underline" />
        </StyledColumn>
      </StyledSection>
    </StyledContent>
  </StyledWrapper>
);

export default AboutBanner;
