export const staticVoivodships = [
  'Cała Polska',
  'dolnośląskie',
  'kujawsko-pomorskie',
  'lubelskie',
  'lubuskie',
  'łódzkie',
  'małopolskie',
  'mazowieckie',
  'opolskie',
  'podkarpackie',
  'podlaskie',
  'pomorskie',
  'śląskie',
  'świętokrzyskie',
  'warmińsko-mazurskie',
  'wielkopolskie',
  'zachodniopomorskie',
];

export const staticCategoty = [
  'Administracja',
  'Badania i rozwój',
  'Bankowość / Ubezpieczenia / Usługi finansowe',
  'BHP / Ochrona środowiska',
  'Budownictwo',
  'Doradztwo / Konsulting',
  'Edukacja / Szkolenia',
  'Energetyka',
  'e-Commerce',
  'Farmacja / Medycyna / Sprzęt medyczny',
  'Finanse / Księgowość',
  'Hotelarstwo / Turystyka',
  'HR / Zasoby ludzkie',
  'Kadra Zarządzająca',
  'Kontrola jakości',
  'Marketing / PR',
  'Media / Reklama / Grafika',
  'Nieruchomości',
  'Obsługa klienta',
  'Prawo',
  'Produkcja / Inżynieria',
  'Sprzedaż',
  'Technical Sales & Service',
  'Transport / Spedycja / Logistyka',
  'Zakupy',
];

export const staticWorkType = ['hybrydowa', 'stacjonarna', 'zdalna'];

export const staticCategotyHrk = [
  'HR Consulting',
  'Payroll Consulting',
  'Rekrutacja i IT Contracting',
  'Zespoły wsparcia',
];

export const staticCity = ['Katowice', 'Kraków', 'Łodź', 'Poznań', 'Sopot', 'Warszawa'];

export const categoryTranslations = {
  PL: {
    Administracja: 'Administracja',
    'Badania i rozwój': 'Badania i rozwój',
    'Bankowość / Ubezpieczenia / Usługi finansowe': 'Bankowość / Ubezpieczenia / Usługi finansowe',
    'BHP / Ochrona środowiska': 'BHP / Ochrona środowiska',
    Budownictwo: 'Budownictwo',
    'Doradztwo / Konsulting': 'Doradztwo / Konsulting',
    'Edukacja / Szkolenia': 'Edukacja / Szkolenia',
    Energetyka: 'Energetyka',
    'Farmacja / Medycyna / Sprzęt medyczny': 'Farmacja / Medycyna / Sprzęt medyczny',
    'Finanse / Księgowość': 'Finanse / Księgowość',
    'Hotelarstwo / Turystyka': 'Hotelarstwo / Turystyka',
    'HR / Zasoby ludzkie': 'HR / Zasoby ludzkie',
    'e-Commerce': 'e-Commerce',
    'Kadra Zarządzająca': 'Kadra Zarządzająca',
    'Kontrola jakości': 'Kontrola jakości',
    'Marketing / PR': 'Marketing / PR',
    'Media / Reklama / Grafika': 'Media / Reklama / Grafika',
    Nieruchomości: 'Nieruchomości',
    'Obsługa klienta': 'Obsługa klienta',
    Prawo: 'Prawo',
    'Produkcja / Inżynieria': 'Produkcja / Inżynieria',
    Sprzedaż: 'Sprzedaż',
    'Technical Sales & Service': 'Technical Sales & Service',
    'Transport / Spedycja / Logistyka': 'Transport / Spedycja / Logistyka',
    Zakupy: 'Zakupy',
    Inne: 'Inne',
    stacjonarna: 'stacjonarna',
    hybrydowa: 'hybrydowa',
    mobilna: 'mobilna',
    zdalna: 'zdalna',
  },
  EN: {
    Administracja: 'Administration',
    'Badania i rozwój': 'Research and development',
    'Bankowość / Ubezpieczenia / Usługi finansowe': 'Banking / Insurance / Financial services',
    'BHP / Ochrona środowiska': 'Occupational health and safety / Environmental protection',
    Budownictwo: 'Construction',
    'Doradztwo / Konsulting': 'Advisory / Consulting',
    'Edukacja / Szkolenia': 'Education / Training',
    Energetyka: 'Energy',
    'Farmacja / Medycyna / Sprzęt medyczny': 'Pharmacy / Medicine / Medical Equipment',
    'Finanse / Księgowość': 'Finance / Accounting',
    'Hotelarstwo / Turystyka': 'Hotel / Tourism',
    'HR / Zasoby ludzkie': 'Human Resources',
    'e-Commerce': 'e-Commerce',
    'Kadra Zarządzająca': 'Executive Managers',
    'Kontrola jakości': 'Quality Control',
    'Marketing / PR': 'Marketing and PR',
    'Media / Reklama / Grafika': 'Media / Advertising / Graphics',
    Nieruchomości: 'Real Estate',
    'Obsługa klienta': 'Customer Service',
    Prawo: 'Law Practice',
    'Produkcja / Inżynieria': 'Manufacturing / Engineering',
    Sprzedaż: 'Sale',
    'Technical Sales & Service': 'Technical Sales & Service',
    'Transport / Spedycja / Logistyka': 'Transport / Shipping / Logistics',
    Zakupy: 'Procurement',
    Inne: 'Other',
    stacjonarna: 'stationary',
    hybrydowa: 'hybrid',
    mobilna: 'mobile',
    zdalna: 'remote',
  },
};
