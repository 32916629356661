import React from 'react';

import Icon, { IconType } from 'components/atoms/icon';
import Typography from 'components/atoms/typography';

import { StyledContent } from '../department-banner/department-banner.styles';
import { StyledFeature, StyledFeatures, StyledTitle, StyledWrapper } from './hero-banner.styles';

export type HeroBannerType = { heading: string; features: { text: string; icon: IconType }[] };

const HeroBanner = ({ heading, features }: HeroBannerType) => (
  <StyledWrapper>
    <StyledContent>
      <StyledTitle variant="title1" html={heading} />
      <StyledFeatures>
        {features.map((feature) => {
          const { icon, text } = feature;

          return (
            <StyledFeature key={icon.name}>
              <Icon {...icon} />
              <Typography color="gray80" variant="body1">
                {text}
              </Typography>
            </StyledFeature>
          );
        })}
      </StyledFeatures>
    </StyledContent>
  </StyledWrapper>
);

export default HeroBanner;
