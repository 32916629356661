import React from 'react';

import JobOffersSearch, { JobOffersSearchType } from 'components/molecules/job-offers-search';
import { WpImageType } from 'components/atoms/wp-image';

import { useScreenService } from 'hooks/use-screen-service';

import {
  StyledDotsGridOne,
  StyledDotsGridTwo,
  StyledImageWrapper,
  StyledWpImage,
  StyledWrapper,
} from './job-offers-banner.styles';

export type JobOffersBannerType = { image: WpImageType; searchBar: JobOffersSearchType ;  };



const JobOffersBanner = ({ image, searchBar }: JobOffersBannerType) => {
  const { isXxlUp, isMdUp } = useScreenService();
  
  return (
    <StyledWrapper>
      <JobOffersSearch {...searchBar}    />
      <StyledImageWrapper>
        <StyledWpImage {...image} />
        <StyledDotsGridOne width={isXxlUp ? 7 : 3} height={isXxlUp ? 7 : 5} color="gray5" />
        <StyledDotsGridTwo width={isMdUp ? 6 : 5} height={7} color="complementary3" />
      </StyledImageWrapper>
    </StyledWrapper>
  );
};

export default JobOffersBanner;
