/* eslint-disable no-unused-vars */
import React from 'react';

import ArticleCard, { PostCard } from 'components/molecules/article-card';
import Slider, { SliderType } from 'components/molecules/slider';
import Button, { ButtonType } from 'components/atoms/button';

import { breakpoints } from 'hooks/use-screen-service';

import {
  StyledContent,
  StyledContentWrapper,
  StyledSubHeading,
  StyledTitle,
  StyledWrapper,
} from './know-how-slider.styles';

export type OtherPostsType = {
  heading: string;
  linkButton: ButtonType;
  postsList: PostCard[];
  postButton: {
    label: string;
    ariaLabel?: string;
  };
  subHeading?: string;
  variant?: 'default' | 'post' | 'post-with-banner';
};

const KnowHowSlider = ({
  heading,
  subHeading,
  linkButton,
  postButton,
  postsList = [],
  variant = 'default',
}: OtherPostsType) => {
  const cardsSlides = postsList?.map((card) => <ArticleCard {...card} {...{ postButton }} />);

  const cardSliderOptions: SliderType['options'] = {
    slidesPerView: 1.2,
    spaceBetween: 16,
    watchSlidesProgress: true,
    breakpoints: {
      [breakpoints.sm]: {
        slidesPerView: 2.2,
      },
      [breakpoints.md]: {
        slidesPerView: 3,
      },
    },
  };

  return (
    <StyledWrapper variant={variant}>
      <StyledContent>
        <StyledContentWrapper>
          {subHeading ? (
            <StyledSubHeading variant="title6" color="gray40" html={subHeading} component="h3" />
          ) : null}
          <StyledTitle variant="title2" color="gray100" html={heading} />
          <Button {...linkButton} variant="underline" width="max-content" />
        </StyledContentWrapper>

        <Slider
          {...{
            slides: cardsSlides,
            options: cardSliderOptions,
            variant: 'secondary',
          }}
        />
      </StyledContent>
    </StyledWrapper>
  );
};

export default KnowHowSlider;
