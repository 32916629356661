import styled from 'styled-components';
import { breakpoint } from 'theme';

import Container from 'components/atoms/container';
import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div`
  padding-block: ${({ theme }) => theme.gap.md};
`;

export const StyledContainer = styled(Container)`
  padding-inline: 0;
  position: relative;
`;
export const BoxNumbers = styled.div`
  z-index: 1;
  background: ${({ theme }) => theme.colors.primary50};
  padding-block: ${({ theme }) => theme.gap.md};
  height: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => theme.gap.md} ${({ theme }) => theme.gap.sm};

  ${breakpoint.lg} {
    position: absolute;
    top: 0;
    right: 11.45%;
    padding: ${({ theme }) => theme.gap.md};
    margin-block: ${({ theme }) => theme.gap.md};
  }
`;

export const StyleTitle = styled(Typography)`
  padding-bottom: ${({ theme }) => theme.gap.md};
`;

export const StyleDescriptionNumber = styled(Typography)`
  &[data-variant='title6'] {
    font-weight: 400;
  }
`;

export const StyleItemNumber = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.whiteAlpha40};
    margin-bottom: ${({ theme }) => theme.gap.sm};
    padding-bottom: ${({ theme }) => theme.gap.sm};

    ${breakpoint.lg} {
      margin-bottom: 40px;
      padding-bottom: 24px;
    }
  }
`;
