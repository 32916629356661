import styled, { css } from 'styled-components';
import { breakpoint } from 'theme';

import Typography from 'components/atoms/typography';

import { OtherPostsType } from './know-how-slider';

export const StyledContentWrapper = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.wrapper};
  margin-bottom: ${({ theme }) => theme.gap.md};
  padding-right: ${({ theme }) => theme.gap.sm};
`;

export const StyledTitle = styled(Typography)`
  padding-block: ${({ theme }) => theme.gap.xs};

  ${breakpoint.md} {
    padding-block: ${({ theme }) => `${theme.gap.sm} 24px`};
    max-width: 825px;
  }
`;

export const StyledWrapper = styled.div<Pick<OtherPostsType, 'variant'>>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: ${({ theme }) => theme.gap.sm};
  padding-block: 36px;
  background: ${({ theme }) => theme.colors.grayscaleGray0};
  position: relative;

  .swiper {
    &-wrapper {
      justify-content: flex-start;
    }
  }

  ${breakpoint.md} {
    padding-block: ${({ theme }) => theme.gap.xl};

    ${({ variant }) =>
      variant === 'default' &&
      css`
        &::before {
          content: '';
          background: ${({ theme }) => theme.colors.grayscaleGray0};
          width: 100%;
          height: 50%;
          position: absolute;
          top: -15%;
          left: 0;
          z-index: -1;
        }
      `}

    ${({ variant }) =>
      variant === 'post' &&
      css`
        ${StyledContentWrapper} {
          ${breakpoint.lg} {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      `}

      ${({ variant }) =>
      variant === 'post-with-banner' &&
      css`
        &::before {
          content: '';
          background: ${({ theme }) => theme.colors.grayscaleGray0};
          width: 100%;
          height: 50%;
          position: absolute;
          top: -15%;
          left: 0;
          z-index: -1;
        }

        ${StyledContentWrapper} {
          ${breakpoint.lg} {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      `}
  }
`;

export const StyledContent = styled.div`
  max-width: ${({ theme }) => theme.wrapper};
  width: 100%;
`;

export const StyledSubHeading = styled(Typography)``;
